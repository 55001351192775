<template>
    <div class="construction-projects-view">
        <div class="construction-projects-view__filters">
            <base-input
                v-model="filterSearch"
                :debounce="400"
                :placeholder="$t('filterByQuery')"
            >
                <template #icon>
                    <search-icon width="16" height="16" />
                </template>
            </base-input>

            <base-multiselect
                v-model="filterLocation"
                :placeholder="$t('filterByLocation')"
                :options="getLocationsSortedByName"
                track-by="id"
                label="name"
                multiple
                block
            />
        </div>

        <v-spinner
            v-if="isLoading"
            class="l-padded"
            size="medium"
            line-fg-color="black"
            line-bg-color="transparent"
            :speed="1"
        />

        <construction-projects-table-view
            v-else
            class="l-full-width"
            :filter-location="filterLocation"
            :filter-search="filterSearch"
            :items="items"
            @clearFilter="clearFilter"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VSpinner from 'vue-simple-spinner'

import { httpHelper } from '@/utils'
import BaseInput from '../redesigned/BaseInput'
import BaseMultiselect from '../redesigned/BaseMultiselect'
import ConstructionProjectsTableView from './ConstructionProjectsTableView'
import SearchIcon from '../icons/SearchIcon'

export default {
    name: 'ConstructionProjectsView',
    components: {
        BaseInput,
        BaseMultiselect,
        ConstructionProjectsTableView,
        SearchIcon,
        VSpinner,
    },
    data() {
        return {
            filterLocation: [],
            filterSearch: '',
            isLoading: false,
            items: [],
        }
    },
    computed: {
        ...mapGetters('locations', ['getLocationsSortedByName']),
    },
    async mounted() {
        this.isLoading = true
        const { data } = await httpHelper.get('/construction-projects/')
        this.items = data.results
        this.isLoading = false
    },
    methods: {
        clearFilter() {
            this.filterLocation = []
            this.filterSearch = ''
        },
    },
}
</script>

<i18n>
{
    "en": {
        "filterByLocation": "Location",
        "filterByQuery": "Name, Adresse oder Auftragsnummer"
    },
    "de": {
        "filterByLocation": "Standort",
        "filterByQuery": "Name, Adresse oder Auftragsnummer"
    },
    "it": {
        "filterByLocation": "Locazione",
        "filterByQuery": "Name, Adresse oder Auftragsnummer"
    }
}
</i18n>

<style lang="scss" scoped>
.construction-projects-view {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__filters {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 1.5rem 1rem 0.5rem 2rem;
        background-color: $color-gray-lighter-new;
        border-bottom: $style-border;

        & > * {
            margin-right: 1rem;
            margin-bottom: 1rem;
        }

        .base-input {
            max-width: 300px;
            width: 100%;
        }

        .multiselect {
            max-width: 200px;
        }
    }

    @include respond-to('for-small-mobile-only') {
        &__filters {
            padding: 1rem 1rem 0;

            & > * {
                margin-right: 0;
            }

            .base-input,
            .multiselect {
                max-width: 100%;
                width: 100%;
            }
        }
    }
}
</style>
